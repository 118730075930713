
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { AxiosError } from 'axios';

import axios from 'axios';

import {
  defineComponent,
  defineAsyncComponent,
  ref,
  reactive,
  computed,
  onMounted,
} from 'vue';

import { useI18n } from 'vue-i18n';

import { useToast } from 'primevue/usetoast';

import type {
  GenericObject,
  ErrorsMap,
  Option,
  Projects,
  Users,
  Payment,
  Payments,
} from '@/types';

import {
  ENTRIES,
  PAYMENT_METHOD_MANUALLY,
  PAYMENT_METHOD_COINBASE,
  PAYMENT_METHOD_STRIPE,
  PAYMENT_METHODS,
  PAYMENT_METHODS_MAP,
  PAYMENT_STATUS_NEW,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUSES,
  PAYMENT_STATUSES_MAP,
  PAYMENT_AMOUNT_MIN,
  PAYMENT_AMOUNT_MIN_STAFF,
  PAYMENT_METHOD_CAPITALIST,
  PAYMENT_METHOD_PAYONEER,
} from '@/libs/consts';

import useUser from '@/composable/useUser';

import useEntriesNext from '@/composable/useEntries@next';

export default defineComponent({
  components: {
    UserColumn: defineAsyncComponent(() => import('@/components/UserColumn.vue')),
    StatisticsByPayments: defineAsyncComponent(() => import('@/components/Widgets/StatisticsByPayments.vue')),
    BalancesSum: defineAsyncComponent(() => import('@/components/Widgets/BalancesSum.vue')),
  },
  setup() {
    const i18n = useI18n();

    const toast = useToast();

    const { activeUser } = useUser();

    const {
      entries: projects,
      entriesLoading: projectsLoading,
      fetchEntries: fetchProjects,
    } = useEntriesNext<Projects>('/api/project/getProjects', ENTRIES);

    const {
      entries: users,
      entriesLoading: usersLoading,
      fetchEntries: fetchUsers,
    } = useEntriesNext<Users>('/api/user/getUsers', ENTRIES);

    const {
      entries: payments,
      fetchEntries: fetchPayments,
    } = useEntriesNext<Payments>('/api/payment/getPaymentsPaginator', ENTRIES);

    const paymentsFilters = reactive({
      project_id: null,
      user_id: null,
      method: null,
      status: null,
    });

    const paymentLoading = ref<boolean>(false);

    const paymentAmountMin = computed((): number => (activeUser.isAffiliate() ? PAYMENT_AMOUNT_MIN : PAYMENT_AMOUNT_MIN_STAFF));

    const paymentForm = reactive<GenericObject>({
      method: null,
      amount: paymentAmountMin.value,
      promocode: null,
      back_url: window.location.href,
    });

    const paymentFormErrors = ref<ErrorsMap>({});

    // eslint-disable-next-line arrow-body-style
    const filteredPaymentMethods = computed((): Option[] => {
      return PAYMENT_METHODS
        .filter((option: Option) => option.value !== PAYMENT_METHOD_MANUALLY)
        .map((option: Option) => ({
          value: option.value,
          translate_key: option.translate_key,
          disabled: (option.value === PAYMENT_METHOD_STRIPE && (activeUser.user_from_restricted_area || !activeUser.enable_pay_by_card))
            || option.value === PAYMENT_METHOD_CAPITALIST
            || option.value === PAYMENT_METHOD_PAYONEER
            || option.value === PAYMENT_METHOD_COINBASE,
        }));
    });

    const fetchPaymentsWrapper = async (): Promise<void> => fetchPayments({
      ...paymentsFilters,
    });

    const fetchUsersWrapper = async (): Promise<void> => fetchUsers({
      project_id: activeUser.isSuperAdmin() ? paymentsFilters.project_id : null,
    });

    const onProjectChange = (): void => {
      paymentsFilters.user_id = null;

      fetchUsersWrapper();
    };

    const addPayment = async (): Promise<void> => {
      paymentLoading.value = true;

      paymentFormErrors.value = {};

      try {
        const response = await axios.post('/api/payment/addPaymentViaPaymentService', paymentForm);

        const payment = response.data as Payment;

        payments.data.unshift(payment);

        if (payment.payment_url) {
          window.location.href = payment.payment_url;
        }
      } catch (error: unknown | AxiosError) {
        // В случае ошибки валидации API возвращает ответ с кодом 422
        if (axios.isAxiosError(error) && error.response?.status === 422) {
          paymentFormErrors.value = error.response.data;

          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('validation_error'),
            life: 5000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: i18n.t('error'),
            detail: i18n.t('unknown_error'),
            life: 5000,
          });
        }
      }

      paymentLoading.value = false;
    };

    onMounted((): void => {
      Promise.all([
        activeUser.isSuperAdmin() ? fetchProjects() : Promise.resolve(),
        !activeUser.isAffiliate() ? fetchUsersWrapper() : Promise.resolve(),
      ]);
    });

    return {
      PAYMENT_METHODS_MAP,
      PAYMENT_STATUS_NEW,
      PAYMENT_STATUS_COMPLETED,
      PAYMENT_STATUSES,
      PAYMENT_STATUSES_MAP,
      activeUser,
      projects,
      projectsLoading,
      users,
      usersLoading,
      payments,
      paymentsFilters,
      paymentLoading,
      paymentAmountMin,
      paymentForm,
      paymentFormErrors,
      filteredPaymentMethods,
      fetchPaymentsWrapper,
      onProjectChange,
      addPayment,
    };
  },
});
